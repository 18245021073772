<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :href="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="6" class="pb-1 pt-0 h-100">
                    <office-login></office-login>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1 h-100">
                    <span class="headline font-weight-medium">Cuentas bancarias</span>
                </v-col>
                <v-col cols="2" class="pt-1 h-100">
                    <v-btn v-if="this.$hasPermision('cuentas-bancarias.create')" 
                        dark 
                        color="#023145" 
                        width="140" 
                        class="float-right" 
                        @click="handleShowCreate">
                        Nuevo
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="h-100">
                <v-col cols="12" class="h-100">
                    <v-card class="h-100">
                        <v-data-table
                            v-model="selectedRows"
                            :headers="headers"
                            :items="accounts"
                            :items-per-page="10"
                            class="elevation-1 w-table table-crud"
                            height="60vh"
                            show-select
                            :single-select="true"
                            @item-selected="handleSelectItem"
                        >
                            <template v-slot:top>
                                <span  class="ml-3 pt-3 pb-3 subtitle-1 font-weight-bold w-title-table">
                                    Todas las cuentas
                                </span>
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.bank_id="{item}">
                                <span v-if="item.bank" class="font-weight-medium">{{ item.bank.name }}</span>
                                <span v-if="item.active" class="activo">
                                    <v-icon size="11" color="#4CAF50">fas fa-check</v-icon>
                                    activo
                                </span>
                                <span v-else class="inactivo">
                                    <v-icon size="11" color="#F44336">fas fa-minus</v-icon>
                                    inactivo
                                </span>
                            </template>

                            <template v-slot:item.balance="{item}">
                                <span>{{ item.balance }}</span>
                            </template>

                            <template v-slot:item.actions="{item}">
                                <v-btn icon small dark color="blue" @click="handleViewDetail(item)">
                                    <v-icon size="14">fas fa-bars</v-icon>
                                </v-btn>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <div class="text-center">
            <v-bottom-sheet v-model="show_options_crud" :hide-overlay="true" persistent :retain-focus="false">
                <v-sheet class="text-center" height="85px">
                    <div class="py-3">
                        <v-btn v-if="this.$hasPermision('cuentas-bancarias.edit')" 
                            class="mt-3" dark color="blue" outlined @click="handleEdit">
                            <v-icon class="mr-2" size="16">fas fa-pen</v-icon>
                            Editar
                        </v-btn>
                        <v-btn v-if="show_btn_active && this.$hasPermision('cuentas-bancarias.change_status')" class="ml-4 mt-3" dark color="green" outlined @click="handleChangeStatus">
                            <v-icon class="mr-2" size="16">far fa-check-circle</v-icon>
                            Activar
                        </v-btn>
                        <v-btn v-if="!show_btn_active && this.$hasPermision('cuentas-bancarias.change_status')" class="ml-4 mt-3" dark color="amber" outlined @click="handleChangeStatus">
                            <v-icon class="mr-2" size="16">fas fa-minus-circle</v-icon>
                            Inactivar
                        </v-btn>
                        <v-btn v-if="this.$hasPermision('cuentas-bancarias.destroy')" class="ml-4 mt-3" dark color="red" outlined @click="handleDelete">
                            <v-icon class="mr-2" size="16">fas fa-times-circle</v-icon>
                            Eliminar
                        </v-btn>
                    </div>
                </v-sheet>
            </v-bottom-sheet>
        </div>

        <create-update ref="createUpdate"></create-update>
    </v-container>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import OfficeLogin from '../../../components/auth/OfficeLogin';

import CreateUpdate from '../../../components/accounts/CreateUpdate';

import ArrayTools from '../../../helpers/ArrayTools';

import router from '@/router/index';

export default {
    name: 'Accounts',
    components: {
        CreateUpdate,
        OfficeLogin
    },
    data () {
        return {
            show_options_crud: false,
            show_btn_active: false,
            selectedRows: [],
            itemsBreadcrumbs: [
                {
                    text: 'Configuración',
                    disabled: true,
                    href: '',
                }
            ],
            headers: [
                { text: 'BANCO', value: 'bank_id', sortable: false },
                { text: 'TIPO DE CUENTA', value: 'type', sortable: false },
                { text: 'NÚMERO DE CUENTA', value: 'number_account', sortable: false },
                { text: 'CCI', value: 'cci', sortable: false },
                { text: 'MONEDA', value: 'currency', sortable: false },
                { text: 'SALDO', value: 'balance', align: 'end' },
                { text: '', value: 'actions', sortable: false, align: 'end' }
            ],
        }
    },
    computed: {
        ...mapState('banks', [
            'banks'
        ]),
        ...mapState('accounts', [
            'accounts'
        ])
    },
    methods: {
        ...mapActions('banks', [
            'getBanks'
        ]),
        ...mapActions('accounts', [
            'getAccounts',
            'change_status',
            'delete',
            'selectAccount'
        ]),
        showOptionCrud() {
            this.show_options_crud = this.selectedRows.length > 0
            if (this.show_options_crud) {
                this.show_btn_active = !this.selectedRows[0].active
            }
        },
        handleSelectItem(selected) {
            if (selected.value) {
                this.selectedRows.push(selected.item)
            } else {
                let index_found = ArrayTools.getIndexById(this.selectedRows, selected.item.id)
                if (index_found > -1) {
                    this.selectedRows.splice(index_found, 1)
                }
            }                     
            this.showOptionCrud()
        },
        handleSelectAll(selected){
            if (selected.value) {
                this.selectedRows = selected.items
            } else {
                this.selectedRows = []
            }
            this.showOptionCrud()
        },
        handleShowCreate () {
            this.$refs.createUpdate.show('Nueva Cuenta Bancaria', 'create');
        },
        handleEdit() {
            let account = this.selectedRows[0];
            this.selectedRows = [];
            this.showOptionCrud();
            this.$refs.createUpdate.show('Modificar Cuenta Bancaria', 'update', account);
        },
        async handleChangeStatus() {
            let account = this.selectedRows[0];
            this.selectedRows = [];
            this.showOptionCrud();
            await this.change_status(account);
        },
        async handleDelete() {
            let account = this.selectedRows[0];
            await this.delete(account);
            this.selectedRows = [];
            this.showOptionCrud();
        },
        handleViewDetail (account) {
            this.selectAccount(account);
            router.push('/app/configuracion/estado-cuenta');
        }
    },
    created () {
        this.selectAccount(null);
        this.getBanks();
        this.getAccounts();
    }
}
</script>

<style scoped>

</style>