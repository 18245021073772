<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>{{ titleForm }}</span>
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            ref="bank_id"
                            v-model="form.bank_id"
                            :items="banks"
                            label="Seleccione banco"
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            :rules="[() => !!form.bank_id || 'Es necesario seleccionar un banco']"
                            hide-details="auto"
                            required
                        ></v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            ref="type"
                            v-model="form.type"
                            label="Tipo de cuenta"
                            :items="types"
                            outlined
                            :rules="[() => !!form.type || 'Es necesario seleccionar un tipo']"
                            hide-details="auto"
                            required>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            ref="number_account"
                            v-model="form.number_account"
                            outlined
                            label="Número de cuenta"
                            :rules="[() => !!form.number_account || 'Es necesario el número de cuenta']"
                            hide-details="auto"
                            required>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            ref="cci"
                            v-model="form.cci"
                            outlined
                            label="Código interbancario"
                            :rules="[() => !!form.cci || 'Es necesario cci']"
                            hide-details="auto"
                            required>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            ref="currency"
                            v-model="form.currency"
                            label="Moneda"
                            :items="currencies"
                            outlined
                            :rules="[() => !!form.currency || 'Es necesario seleccionar la moneda']"
                            hide-details="auto"
                            required>
                        </v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="form.balance"
                            outlined
                            label="Saldo Inicial"
                            type="number"
                            hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </div>
        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn v-if="action == 'create'" dark color="success" block height="40" 
                            @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                        <v-btn v-if="action == 'update'" dark color="success" block height="40" 
                            @click="handleUpdate">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Actualizar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex';
export default {
    name: 'CreateUpdate',
    data () {
        return {
            drawer: false,
            titleForm: null,
            action: null,
            types: ['AHORROS', 'CORRIENTE'],
            currencies: ['SOLES', 'DOLARES'],
            form: {
                id: null,
                bank_id: null,
                type: null,
                number_account: null,
                cci: null,
                currency: null,
                balance: null
            }
        }
    },
    computed: {
        ...mapState('banks', [
            'banks'
        ])
    },
    methods: {
        ...mapActions('accounts', [
            'store',
            'update'
        ]),
        async show(title, action, account = null) {
            await this.clearData();
            this.titleForm = title;
            this.action = action;
            if (account) {
                this.setAccount(account);
            }
            this.drawer = !this.drawer;
        },
        clearData () {
            this.form.id = null;
            this.form.bank_id = null;
            this.form.type = null;
            this.form.number_account = null;
            this.form.cci = null;
            this.form.balance = null;
            this.form.currency = null;
            this.clearErrors();
        },
        closeForm() {
            this.clearData()
            this.drawer = false
        },
        setAccount (account) {
            this.form.id = account.id;
            this.form.bank_id = account.bank_id;
            this.form.type = account.type;
            this.form.number_account = account.number_account;
            this.form.cci = account.cci;
            this.form.balance = account.balance;
            this.form.currency = account.currency;
        },
        customText(item){
            let text = item.name
            
            return text
        },
        handleCancel() {
            this.closeForm()
        },
        async handleStore () {
            if (!this.existErrors()) {
                await this.store(this.form)
                this.closeForm()
            }
        },
        async handleUpdate () {
            if (!this.existErrors()) {
                await this.update(this.form);
                this.closeForm();
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        }
    }
}
</script>

<style scoped>

</style>